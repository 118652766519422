<script>
export default {
  metaInfo: {
    title: "Received Packets",
  }
};
</script>

<script setup>
import DynamicReceivedPacketsView from '@/components/dynamic/ReceivedPacketsView.vue';
</script>

<template>
  <DynamicReceivedPacketsView/>
</template>

<style scoped>

</style>
